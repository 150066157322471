import Link from "./Link";
import React from "react";

class Controls extends React.Component {
  show() {
    return this.props.video.showAt && this.props.time > this.props.video.showAt;
  }

  render() {
    return <div className={`controls${this.show() ? ' active' : ''}`}>
      <div>
        {this.props.video && this.props.video.blue && this.show() ?
          <Link backwards={this.props.video && this.props.video.backwards}
            color="blue"
            id={this.props.video.blue}
            callback={(id) => this.props.lload(id)}
          /> :
          <div className="placeholder">&nbsp;</div>}
        {this.props.video && this.props.video.yellow && this.show() ?
          <Link backwards={this.props.video && this.props.video.backwards}
            color="yellow"
            id={this.props.video.yellow}
            callback={(id) => this.props.lload(id)}
          /> :
          <div className="placeholder">&nbsp;</div>}
      </div>

      <div>
        {this.props.video && this.props.video.green && this.show() ?
          <Link backwards={this.props.video && this.props.video.backwards}
            color="green"
            id={this.props.video.green}
            callback={(id) => this.props.lload(id)}
          /> :
          <div className="placeholder">&nbsp;</div>}
        {this.props.video && this.props.video.extra && this.show() ?
          <div className="extra"><a href="/"
            data-id={this.props.video.extra.id}
            onClick={(event) => {
              event.preventDefault();
              this.props.lload(event.target.dataset.id);
            }}
          >{this.props.video.extra.text}</a></div> : <></>}
        {this.props.video && this.props.video.red && this.show() ?
          <Link backwards={this.props.video && this.props.video.backwards}
            color="red"
            id={this.props.video.red}
            callback={(id) => this.props.lload(id)}
          /> :
          <div className="placeholder">&nbsp;</div>}
      </div>
    </div>
  }
}


export default Controls;
