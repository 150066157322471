import React from 'react';
import ReactGA from 'react-ga';

const Link = ({color, id, callback, backwards, children: text}) => {
  return <button onClick={() => {
    ReactGA.event({category: 'option', action: 'click', label: color});

    return callback(id);
  }}
    className={`${color} ${backwards ? 'backwards' : ''}`}
  >{text ? text : ' '}</button>
};

export default Link;