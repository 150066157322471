import React from 'react';
import DarkRoom from "./DarkRoom";
import {withRouter} from 'react-router'
import ReactGA from 'react-ga';

class App extends React.Component {
  state = {};

  constructor(props) {
    super(props);

    ReactGA.pageview(`/${window.location.hash.replace('#', '')}`);

    this.state.videoId = props.location.hash.replace('#', '') || props.startAt;

    props.history.listen((location) => {
      ReactGA.pageview(`/${window.location.hash.replace('#', '')}`);
      this.setState({videoId: location.hash.replace('#', '')});
    })
  }

  render() {
    return (
      <div>
        <DarkRoom id={this.state.videoId} video={this.props.videos[this.state.videoId]} lload={(videoId) => {
          this.props.history.push(`#${videoId}`);
        }}
        />
      </div>
    );
  }

}

export default withRouter(App);
