import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import results from './results';
import './style.scss'
import {BrowserRouter as Router} from 'react-router-dom';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-156465917-2');

ReactDOM.render(
  <Router>
    <App videos={results} startAt="hvkjP6dqpfY" />
  </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
